import "@popperjs/core";
import "bootstrap";
import { ValidationService } from "aspnet-client-validation";
import "../styles/index.scss";

import "../scripts/components/header";
import "../scripts/components/progressbar";
import "../scripts/components/resourceLanding";

import "../scripts/components/form-submission-repopuation";
import "../scripts/components/cookie-bot-functions";
import "../scripts/components/select";
import "../scripts/components/tabs";
import "../scripts/components/faqs";
import "../scripts/components/resourcesContainer";
import "../scripts/components/recite-me";
import "../scripts/components/iframe-loading";
// ASP.NET Core Client Side Validation Setup
const service = new ValidationService();
service.bootstrap();