import Choices from "choices.js";

class ChoicesSelect
{
	constructor(el: HTMLSelectElement)
	{
		var episerverForms = el.closest(".EPiServerForms");

		if (episerverForms != null)
		{
			var formsID = episerverForms.id;

			var formSession = sessionStorage.getItem(formsID);
			var elementID = el.parentElement?.dataset.fElementName

			if (formSession != null && elementID != null)
			{
				var formSessionJson = JSON.parse(formSession)

				var selectItemValue = formSessionJson[elementID]
				if (selectItemValue != null)
				{
					el.value = selectItemValue[0];
				}
			}
		}

		new Choices(el, {
			searchEnabled: false,
			itemSelectText: '',
		});
	}
}

document.querySelectorAll("select").forEach(el => {
	new ChoicesSelect(el);
});
