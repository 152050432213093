declare global
{
	interface Window
	{
		formData: any;
	}
}

class FormSubmissionRepopulation
{
	constructor(el: Element)
	{
		if (window.formData != null)
		{
			var formDataJson = JSON.parse(window.formData);

			Object.entries(formDataJson).forEach((entry) =>
			{
				const [key, value] = entry;

				var htmlInputElement = document.querySelector<HTMLInputElement>(`[name=${key}]`);

				if (htmlInputElement != null)
				{
					if (htmlInputElement.type == "radio")
					{
						var radioElement = document.querySelector<HTMLInputElement>(`[name="${key}"][value="${value}"]`);

						if (radioElement != null)
						{
							radioElement.checked = true;
						}
					} else if (htmlInputElement.type == "checkbox")
					{
						if (value == "1")
						{
							htmlInputElement.checked = true;
						}
					} else
					{
						htmlInputElement.value = value as string;
					}
				}
			});
		}

	}
}

document.querySelectorAll(".EPiServerForms").forEach(el =>
{
	new FormSubmissionRepopulation(el);
});
