document.querySelectorAll(".iframe-block").forEach(el =>
{
	var iframe = el.querySelector("iframe");

	if (iframe != null)
	{
		iframe.onload = () =>
		{
			var loadingDiv = el.querySelector<HTMLElement>(".iframe-block__loading");

			if (loadingDiv != null)
			{
				loadingDiv.style.display = "none";
			}
		}
	}
	
});