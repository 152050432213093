declare var $$epiforms: any;

class Progressbar {
    el: Element;	
    step: number;
    maxSteps: number;
	progressBar: Element | null;
	navigationBar: Element | null;
	submitButton: Element | null;
	progressBarDescription: Element | null;

	constructor(el: Element) {
		this.el = el;
		this.step = 0;
		this.maxSteps = el.querySelectorAll(".FormStep").length;
		this.progressBar = el.querySelector(".progress-bar");
		this.navigationBar = el.querySelector(".Form__NavigationBar");
		this.submitButton = el.querySelector(".FormSubmitButton");
		this.progressBarDescription = el.querySelector(".progress-bar-description");
		this.nextStepEvent();
		this.previousStepEvent();

		this.setSubmitButtonLocation();
		this.flickerPersonalisation();
	}

	flickerPersonalisation()
	{
		this.submitButton?.addEventListener("click", () =>
		{
			document.querySelectorAll(".FormStep:not(.hide) .FormParagraphText.hide").forEach(el =>
			{
				el.remove();
			})
		});
	} 

	nextStepEvent() {
		$$epiforms(this.el).on("formsNavigationNextStep", (event: any) =>
		{
			this.step = event.targetStep.index;

			this.updateProgressBar();
		});
	}

	previousStepEvent() {
		$$epiforms(this.el).on("formsNavigationPrevStep", (event: any) => {
			this.step = event.targetStep.index;

			this.updateProgressBar();
		});
	}

	updateProgressBar()
	{
		this.toggleSubmitButtonVisibility();

		this.progressBar?.querySelectorAll(".progress-bar__circle").forEach(item =>
		{
			var circleElement = item as HTMLElement;
			if (circleElement.dataset.step && Number.parseInt(circleElement.dataset.step) < this.step)
			{
				circleElement.classList.add("progress-bar__circle--active");
				circleElement.classList.remove("progress-bar__circle--current");
			} else
			{
				circleElement.classList.remove("progress-bar__circle--active");
			}
		});

		this.progressBarDescription?.querySelectorAll("[data-step]").forEach(item =>
		{
			var description = item as HTMLElement;

			if (description.dataset.step && Number.parseInt(description.dataset.step) == this.step)
			{
				description.classList.remove("progress-bar-description--hidden")
			} else
			{
				description.classList.add("progress-bar-description--hidden")
			}
		});

		this.progressBar?.querySelector(`.progress-bar__circle[data-step="${this.step}"]`)?.classList.add("progress-bar__circle--current");
		this.progressBar?.querySelector(`.progress-bar__circle[data-step="${this.step}"]`)?.classList.add("progress-bar__circle--active")
	}

	toggleSubmitButtonVisibility()
	{
		
		if (this.step == this.maxSteps - 1) {
			this.submitButton?.removeAttribute("disabled");
		} else {
			this.submitButton?.setAttribute("disabled", "");
		}
	}

	setSubmitButtonLocation() {
		if (this.submitButton !== null) {
			this.navigationBar?.append(this.submitButton);

			this.toggleSubmitButtonVisibility();
		}
	}
}


document.querySelectorAll(".EPiServerForms").forEach(el =>
{
	if (el.querySelector(".progress-bar"))
	{
		new Progressbar(el);
	}
})
