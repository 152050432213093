
declare var Cookiebot: any;

class CookieBotFunctions
{
	constructor(el: Element)
	{
		document.querySelectorAll(".swapCookieTable").forEach(target =>
		{
			target.addEventListener("click", () =>
			{
				var cookieType = target.getAttribute("data-cookie-type");

				if (cookieType != null)
				{
					this.swapCookieTable(cookieType);
				}
			});
		})

		document.querySelectorAll(".expandCookie").forEach(target =>
		{
			target.addEventListener("click", () =>
			{
				var expandType = target.getAttribute("data-cookie-expand");
				if (expandType != null)
				{
					this.expandCookieDetails(expandType);
				}
			});
		});

		document.querySelectorAll(".openModal").forEach(target =>
		{
			target.addEventListener("click", () =>
			{
				var modalType = target.getAttribute("data-cookie-modal");
				if (modalType != null)
				{
					this.openModal(modalType);
				}
			});
		});

		document.querySelectorAll(".closeCookieBanner").forEach(target =>
		{
			target.addEventListener("click", () =>
			{
				this.closeCookieBanner();
			});
		});

		document.querySelectorAll(".submitConsent").forEach(target =>
		{
			target.addEventListener("click", () =>
			{
				Cookiebot.dialog.submitConsent();
			});
		});
	}

	swapCookieTable(cookieType: string)
	{
		var c_necessary = document.getElementById("c_necessary");
		var c_preference = document.getElementById("c_preference");
		var c_statistics = document.getElementById("c_statistics");
		var c_advertising = document.getElementById("c_advertising");

		if (c_necessary == null || c_preference == null || c_statistics == null || c_advertising == null)
		{
			return;
		}

		switch (cookieType)
		{
			case "c_necessary":
				if (c_necessary.style.display == "block")
				{
					c_necessary.style.display = "none";
				}
				else
				{
					c_necessary.style.display = "block";
					c_preference.style.display = "none";
					c_statistics.style.display = "none";
					c_advertising.style.display = "none";
				}
				break;
			case "c_preference":
				if (c_preference.style.display == "block")
				{
					c_preference.style.display = "none";
				}
				else
				{
					c_necessary.style.display = "none";
					c_preference.style.display = "block";
					c_statistics.style.display = "none";
					c_advertising.style.display = "none";
				}
				break;
			case "c_statistics":
				if (c_statistics.style.display == "block")
				{
					c_statistics.style.display = "none";
				}
				else
				{
					c_necessary.style.display = "none";
					c_preference.style.display = "none";
					c_statistics.style.display = "block";
					c_advertising.style.display = "none";
				}
				break;
			case "c_advertising":
				if (c_advertising.style.display == "block")
				{
					c_advertising.style.display = "none";
				}
				else
				{
					c_necessary.style.display = "none";
					c_preference.style.display = "none";
					c_statistics.style.display = "none";
					c_advertising.style.display = "block";
				}
				break;
		}
	}
	expandCookieDetails(toggleType: string)
	{
		var a_show = document.getElementById("expandCookieDetailsShow");
		var a_hide = document.getElementById("expandCookieDetailsHide");
		var ce = document.getElementById("cookieExpand");

		if (a_show == null || a_hide == null || ce == null)
		{
			return;
		}

		if (toggleType == "show")
		{
			a_show.style.display = "none";
			a_hide.style.display = "block";
			ce.style.display = "block";
		}
		if (toggleType == "hide")
		{
			a_show.style.display = "block";
			a_hide.style.display = "none";
			ce.style.display = "none";
		}
	}

	openModal(openClose: string)
	{
		var cookiemodal = document.getElementById("cookiebannermodal");
		var cookieBottomBanner = document.getElementById("cookiebanner");

		if (cookiemodal == null || cookieBottomBanner == null)
		{
			return;
		}
		if (openClose == "open")
		{
			cookiemodal.style.display = "block";
		}
		else
		{
			cookiemodal.style.display = "none";
			cookieBottomBanner.style.display = "none";
		}
	}
	closeCookieBanner()
	{
		var cookieBottomBanner = document.getElementById("cookiebanner");
		if (cookieBottomBanner != null)
		{
			cookieBottomBanner.style.display = "none";
		}
	}

}

document.querySelectorAll("#cookiebanner").forEach(el =>
{
	new CookieBotFunctions(el);
});