declare global
{
	interface Window
	{
		loadService: any;
	}
}

document.addEventListener("DOMContentLoaded", function (event)
{
	document.querySelectorAll("#enableRecite").forEach(el =>
	{
		el.addEventListener("click", function ()
		{
			window.loadService();
		});
	})
});